import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from 'react-i18next';
export const SelectMultiInput = props => {
  const { maxItem } = props;
  const [options, setOptions] = useState(props.options || []);

  const applyCallback = value => {
    setOptions(
      options.map(e => {
        e.isDisabled = maxItem && value.length >= maxItem;
        return e;
      })
    );
    if (props.onChange)
      props.onChange({
        target: {
          name: props.name,
          value: (value || []).map(e => e.value)
        }
      });
  };
  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const objValue = options.filter(e => props.value.includes(e.value)) || [];
  const { t } = useTranslation();
  return (
    <>
      {props.readonly ? (
        <div className="fake-form-control">
          {objValue.map(e => e.label).join(", ")}
        </div>
      ) : (
        <Select
          className="zindex1x"
          isMulti
          defaultValue={objValue}
          options={options.map(e => {
            e.label = t(e.label)
            return e
          })}
          name={props.name}
          onChange={applyCallback}
        />
      )}
    </>
  );
};
