import Axios from 'axios'
console.log(process.env)
console.log(process.env.REACT_APP_API_URL)
Axios.defaults.baseURL = 'https://imonqr.com/' || 'http://localhost:8888/'
Axios.defaults.headers.common.Accept = 'application/json'
// Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// let Token = localStorage.getItem('Token')
// if (Token) {
//   Axios.defaults.headers.common['Authorization'] = `${Token}`
// }
Axios.interceptors.response.use(
  response => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)
Axios.interceptors.request.use(config => {
  let token = localStorage.getItem('Token')
  config.headers.Authorization =  token;
  return config
})
Axios.interceptors.response.use(response => {
  return response
})