import BaseService from './base.service'
export class AccountService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('rest-auth/', parameters)
  }
  confirm (code) {
    return this.submit('get', `/api/profile/active/${code}`)
  }
  requestReset (info) {
    return this.submit('post', `/api/user/request_reset`, {info})
  }
  resetPassword (info, token) {
    return this.submit('post', `/api/user/reset_password/${token}`, info)
  }
  changePassword (info) {
    return this.submit('post', `/api/user/change_password`, info)
  }
  login (username, password) {
    return this.submit('post', `/${this.endpoint}login/`, {username, password})
  }
  getInfo () {
    return this.submit('get', `${this.endpoint}user/`)
  }
  logout () {
    localStorage.removeItem('Token')
    // go2Login()
  }
  register (data) {
    // return this.submit('post', `/api/profile/register`, data)
    return this.submit('post', `/api/profile/register_wc`, data)
  }
  
}
