import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Loading } from "src/_components"
import { store, history, JSONParse, getLang, formatUrl } from 'src/_helpers'
import clsx from 'clsx'
export class VCarousel extends Component {
  constructor(props) {
    super(props);
    this.clickItem = this.clickItem.bind(this);
  }
  getLink (data) {
    let appconfig = store.getState().appconfg
    var btn = JSONParse(data.btn) || {}
    if (btn.type === 'link' && btn.url) window.location.replace(btn.url)
    if (btn.type === 'phone' && btn.phone) window.open('tel:' + btn.phone)
    if (btn.type === 'menu' && btn.menu) history.push('/' + appconfig.base_path + '/menu/' + btn.menu)
    if (btn.type === 'article' && btn.article) history.push('/' + appconfig.base_path + '/article/' + btn.article)
    if (btn.type === 'website' && btn.website) history.push('/' + appconfig.base_path + '/website/' + btn.website)
    return ''
  }
  getTitle (data) {
    var btn = JSONParse(data.btn) || {}
    return (
      <>
        {btn.title && btn.position_btn && <div className='ICarousel-title' style={this.getStyle(data)}>{this.getTitleL(btn)}</div>}
      </>
    )
  }
  getTitleL (data) {
    var currentLang = getLang()
    return data['title_' + currentLang] || data.title
  }
  getSrcL (data) {
    var currentLang = getLang()
    return data['src_' + currentLang] || data.src
  }
  getStyle (data) {
    var sty = JSONParse(data.btn) || {}
    return {color: sty.color, backgroundColor: sty.backgroundColor, borderColor: sty.borderColor, fontSize: sty.fontSize || '14'}
  }
  getClassName (data) {
    var btn = JSONParse(data.btn) || {}
    return {
      btnTopLeft: btn.position_btn === 1,
      btnTopRight: btn.position_btn === 2,
      btnBottomLeft: btn.position_btn === 3,
      btnBottomRight: btn.position_btn === 4,
      btnCenter: btn.position_btn === 5,
      ICarousel: true,
    }
  }
  getClassName2 (data) {
    var btn = JSONParse(data.btn) || {}
    return btn.position_btn
  }
  clickItem (a) {
    const { items } = this.props
    var item = items[a]
    if (item) {
      this.getLink(item)
    }
  }
  render() {
    const { items, autoPlay } = this.props
    if (!items) return <Loading />
    if (items.length === 0) return <></>;
    if (items.length === 1) return <div onClick={() => this.clickItem(0)} className={clsx(this.getClassName2(items[0]), this.getClassName(items[0]))}>
      <div className='pla-single-img'><img src={formatUrl(this.getSrcL(items[0]))} alt="" /></div>
      {this.getTitle(items[0])}
    </div>
    return (
      <Carousel
        showArrows={true}
        className="v-carousel"
        onClickItem={this.clickItem}
        infiniteLoop={items.length > 1}
        autoPlay={autoPlay === 'on'}
        dynamicHeight={false}
        showThumbs={false}
        showIndicators={items.length > 1}
        showStatus={false}
      >
        {items.map((item, ind) => {
          return (<div key={ind} className={clsx(this.getClassName2(item), this.getClassName(item))}>
            <img src={formatUrl(items[0].src)} key={item.src} alt="" />
            <div className='pla-img'><img src={formatUrl(this.getSrcL(item))} key={item.src} alt="" /></div>
            {this.getTitle(item)}
          </div>)
        })}
      </Carousel>
    );
  }
}
