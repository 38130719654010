import React, { useState } from 'react'
import {
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText
} from '@coreui/react'
const lev = [0, 20, 40, 60, 80, 100]
export const InputPassword = (props) => {
  const [inputType, setInputType] = useState('password');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const showPass = (e) => {
    setInputType(inputType === 'text' ? 'password' : 'text')
  }
  let attrx = {...props, ...{type: inputType || 'password'}}
  const checkpassword = (password) => {
    var strength = 0;
    var strengthbar = 0;
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1;
  
    }
    if (password.length > 8) {
      strength += 1;
    }
    strengthbar = lev[strength] || 0
    setPasswordStrength(strengthbar)
    props.onChange({
      target: {
        name: props.name,
        value: strengthbar >= 100 || props.noCheck ? password : ''
      }
    })
  }
  return (
    <>
      <CInputGroup>
        <CInput {...attrx} onChange={(e) => checkpassword(e.target.value)}/>
        {!props.noCheck && <ul className={'password-strength password-strength-' + passwordStrength}>
          <li className='li-1'></li>
          <li className='li-2'></li>
          <li className='li-3'></li>
          <li className='li-4'></li>
          <li className='li-5'></li>
        </ul>}
        <CInputGroupAppend>
          <CInputGroupText onClick={() => showPass()} className="pointer">
            {inputType === 'password' ? <span className="fa fa-eye"></span> : <span className="fa fa-eye-slash"></span>}
          </CInputGroupText>
        </CInputGroupAppend>
        {props.note && <i className='pas-note'>Password ít nhất 8 ký tự, bao gồm ký tự chữ, viết hoa, viết thường, và ít nhất 1 ký tự đặc biệt!</i>}
      </CInputGroup>
    </>
  )
}