import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { UploadService } from 'src/_services'
import { getAppConfig } from 'src/_helpers'
const MAXSIZE = 1 // MB
export const CEditorInline = props => {
  const [defaultValue, setValue] = useState(props.value);
  const applyCallback = value => {
    setValue(value)
    if (props.onChange)
      props.onChange({
        target: {
          name: props.name,
          value: value
        }
      });
  };
  return (
    <>
      {props.readonly ?
        <div className="fake-form-control">{defaultValue || 'NA'}</div>
        :
        <Editor
          value={defaultValue}
          inline
          apiKey="masv3v7kgoord7oy94zu6gu0kvi0ii963d335m0ezatvtx5p"
          init={{
            images_dataimg_filter: function (img, a, b) {
              return !img.hasAttribute('internal-blob');  // blocks the upload of <img> elements with the attribute "internal-blob".
            },
            paste_data_images: true,
            automatic_uploads: false,
            height: 500,
            menubar: false,
            extended_valid_elements: "iframe[src|frameborder|style|scrolling|class|width|height|name|align]",
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Allison&display=swap');@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');@font-face { font-family: 'GothamBold'; src: url('/static/fonts/SVN-Gotham.woff2') format('woff2'), url('/static/fonts/SVN-Gotham.woff') format('woff'); font-weight: 500; font-style: normal; font-display: swap; } @font-face { font-family: 'GothamLight'; src: url('/static/fonts/SVN-GothamLight.woff2') format('woff2'), url('/static/fonts/SVN-GothamLight.woff') format('woff'); font-weight: 300; font-style: normal; font-display: swap; }",
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount"
            ],
            font_formats:
              "GothamLight=GothamLight;GothamBold=GothamBold;Fira Sans=Fira Sans;Allison=Allison;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            toolbar:
              "undo redo | styleselect | fontsizeselect fontselect | bold italic | forecolor backcolor | removeformat | help"
          }}
          onEditorChange={applyCallback}
        />
      }
    </>
  );
};
const uploadFile = (file, callback, fail) => {
  return new UploadService().upload(file).then((fileUploaded) => {
    callback(fileUploaded);
  })
    .catch((e) => {
      fail(e)
    })
}
const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
const retrieveImageFromClipboardAsBlob = (pasteEvent) => {
  if (pasteEvent.clipboardData === false) {
    return false;
  }

  var items = pasteEvent.clipboardData.items;

  if (items === undefined) {
    return false;
  }

  for (var i = 0; i < items.length; i++) {
    // Only paste if image is only choice
    if (items[i].type.indexOf("image") === -1) {
      return false;
    }
    // Retrieve image on clipboard as blob
    var blob = items[i].getAsFile();
    // load image if there is a pasted image
    if (blob !== null) {
      const reader = new FileReader();
      reader.onload = function (e) {
        // console.log('result', e.target.result);
      };
      reader.readAsDataURL(blob);
      return blob;
    }
  }
  return false;
}
export const CEditor = props => {
  const [defaultValue, setValue] = useState(props.value);
  var maxFileSize = getAppConfig('UPLOADFILESIZE')
  if (maxFileSize) {
    maxFileSize = parseInt(maxFileSize)
  }
  const applyCallback = value => {
    setValue(value)
    if (props.onChange)
      props.onChange({
        target: {
          name: props.name,
          value: value ? value.replaceAll('"static/uploads/', '"https://imonqr.com/static/uploads/') : value
        }
      });
  };
  return (
    <>
      {props.readonly ?
        <div className="fake-form-control">{defaultValue || 'NA'}</div>
        :
        <Editor
          value={defaultValue || '<div></div>'}
          apiKey="masv3v7kgoord7oy94zu6gu0kvi0ii963d335m0ezatvtx5p"

          init={{
            setup: (editor) => {
              editor.on('paste', (e) => {
                var imageBlob = retrieveImageFromClipboardAsBlob(e);
                if (!imageBlob) {
                  return;
                }
                if (imageBlob.size / (1024 * 1024) > (maxFileSize || MAXSIZE)) {
                  alert(`File size is ${formatBytes(imageBlob.size)}, max is ${maxFileSize || MAXSIZE}MB`)
                  // 'file size is ' + imageBlob.size + ', max is 2MB')
                  return;
                }
                console.log(imageBlob.size)
                e.preventDefault();
                uploadFile(imageBlob, response => {
                  if (response && editor) {
                    editor.insertContent('<img class="_post_img" src="https://imonqr.com' + response + '" />');
                  }
                }, () => {
                  alert('Có lỗi xảy ra, vui lòng thử lại')
                });
              })
            },
            automatic_uploads: false,
            height: 500,
            menubar: false,
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Allison&display=swap');@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');@font-face { font-family: 'GothamBold'; src: url('/static/fonts/SVN-Gotham.woff2') format('woff2'), url('/static/fonts/SVN-Gotham.woff') format('woff'); font-weight: 500; font-style: normal; font-display: swap; } @font-face { font-family: 'GothamLight'; src: url('/static/fonts/SVN-GothamLight.woff2') format('woff2'), url('/static/fonts/SVN-GothamLight.woff') format('woff'); font-weight: 300; font-style: normal; font-display: swap; }",
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
              "code preview"
            ],
            font_formats:
              "GothamLight=GothamLight;GothamBold=GothamBold;Fira Sans=Fira Sans;Allison=Allison;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            toolbar:
              "undo redo | styleselect | fontsizeselect fontselect | bold italic | forecolor backcolor image | bullist numlist outdent indent | preview | help"
          }}
          onEditorChange={applyCallback}
        />
      }
    </>
  );
};
