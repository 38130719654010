import React, { useState } from 'react'
import { getListLanguage, getAppConfig } from 'src/_helpers'
import {
  CTextarea
} from '@coreui/react'
import {
  InputText, CEditor, Uploader
} from "src/_components";
export const TransInline = (props) => {
  const defaultLanguage = getAppConfig('DEFAULTLANGUAGE') || 'vi-VN'
  const [currentLang, setCurrentLang] = useState(defaultLanguage);
  const ListLang = getListLanguage()
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (props.onChange) {
      props.onChange({
        target: {
          name: name,
          value: value
        }
      });
    }
  }
  const applyCallbackFiles = (a, b) => {
    if (props.onChange) {
      props.onChange(a, b);
    }
  }
  const inputForm = () => {
    var { fieldName } = props
    var name = `${fieldName}_${currentLang}`
    var val = props.value[name]
    if (props.type === 'CEditor') return <>
      <b>{props.label || props.fieldName} ({(ListLang.find(e => e.code === currentLang) || {}).label})</b>
      <CEditor
        key={name}
        name={name}
        value={val}
        onChange={handleChange}
      />
    </>
    if (props.type === 'CTextarea') return <>
      <b>{props.label || props.fieldName} ({(ListLang.find(e => e.code === currentLang) || {}).label})</b>
        <CTextarea
          name={name}
          value={val}
          onChange={handleChange}
          rows="3"
        />
    </>
    if (props.type === 'Uploader') return <Uploader
      applyCallback={applyCallbackFiles}
      name={name}
      value={val}
      key={name}
    />
    return <><b>{props.label || props.fieldName} ({(ListLang.find(e => e.code === currentLang) || {}).label})</b>
      <InputText
        type="text"
        name={name}
        value={val}
        key={name}
        onChange={handleChange}
      />
    </>
  }
  return (
    <>
      {defaultLanguage === currentLang && props.children}
      {defaultLanguage !== currentLang && inputForm()}
      <div className='list-lang'>
        <span className={'pointer item-l ' + (currentLang === defaultLanguage ? 'active' : '')} onClick={() => setCurrentLang(defaultLanguage)}><img alt='qr-flag0i' src={`/static/images/flag/${defaultLanguage}.png`} /></span>
        {ListLang.map(l => {
          return <span className={'pointer item-l ' + (currentLang === l.code ? 'active' : '') } onClick={() => setCurrentLang(l.code)} key={l.code}><img alt='qr-flag0i' src={`/static/images/flag/${l.code}.png`} /></span>
        })}
      </div>
    </>
  )
}
