import React, { useState } from 'react'
import {
  CInputGroup,
  CButton,
  CInputGroupAppend
} from "@coreui/react";
import { VIcon, TransText, InputText } from "src/_components";
import { history } from 'src/_helpers'
export const PageFilterBox = (props) => {
  const [filterPar, setValue] = useState({});
  const applyCallback = (e) => {
    const { name, value, checked, type} = e.target
    filterPar[name] =  type === "checkbox" ? checked : value;
    setValue(filterPar)
  }
  return (<div className="product-list-header">
  <div className="d-flex justify-content-between">
    <div className="">
      <CInputGroup>
        <InputText
          id="input3-group2"
          name="search"
          placeholder="Nhập để tìm kiếm"
          onChange={applyCallback}
        />
        <CInputGroupAppend>
          <CButton
            type="button"
            color="primary"
            onClick={() => props.onFilterBaseSubmit(filterPar)}
          >
            <VIcon name="cilSearch" />
          </CButton>
        </CInputGroupAppend>
      </CInputGroup>
    </div>
    <div className="">
      <CButton
          color="secondary"
          className="left"
          size="sm"
          onClick={() => history.push(props.btnNewAction)}
        >
          <TransText>Tạo mới</TransText>
        </CButton>
    </div>
  </div>
</div>)
}