import React, { useState, useEffect } from "react";
import {
  CInput,
  CInputGroup, CInputGroupAppend, CInputGroupText
} from '@coreui/react'
import { useTranslation } from 'react-i18next';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {
  isMobileOnly
} from "react-device-detect";
import InputColor from 'react-input-color';
export const InputText = (props) => {
  const [isOpen, setCamera] = useState(false);
  const [color, setColor] = useState(props.value || '');
  const { t } = useTranslation();
  useEffect(() => {
    setColor(props.value);
  }, [props.value]);
  return (
    <>
      {props.barcode && isMobileOnly ?
      <>
        {isOpen && <div className="BarcodeScanner" onClick={() => setCamera(false)}>
        <BarcodeScannerComponent
          width={500}
          height={500}
          onUpdate={(err, result) => {
            if (result) {
              if (props.onChange)
                props.onChange({
                  target: {
                    name: props.name,
                    value: result.text
                  }
                });
              setCamera(false)
            }
            else console.log('Not Found')
          }}
        /></div>
        }
        <CInputGroup>
          <CInput autoComplete="off" {...props} placeholder={t(props.placeholder)}/>
          <CInputGroupAppend>
            <CInputGroupText className={'bg-info text-white'} onClick={() => setCamera(true)}>
              <span className="fa fa-barcode"></span>
            </CInputGroupText>
          </CInputGroupAppend>
        </CInputGroup>
      </> : <>
        {props.datatype === 'color' ?
          <>
            <CInputGroup className={'color-input-group'}>
              <CInput autoComplete="off" {...props} style={{ backgroundColor: color }} />
              <CInputGroupAppend>
                <InputColor
                  initialValue={color || ''}
                  onChange={(e) => props.onChange({
                    target: {
                      name: props.name, value: e.hex
                    }
                  })}
                  placement="right"
                />
              </CInputGroupAppend>
            </CInputGroup>
          </> : 
            <CInput autoComplete="off" {...props}  placeholder={t(props.placeholder)}/>
          }
        </>
      }
    </>
  )
}