import React, { useState } from 'react'
import {
  CTextarea, CButton
} from '@coreui/react'
import { store, getTranText2, history } from 'src/_helpers'
import { FeedbackService } from "src/_services";
export const Feedback = (props) => {
  var [rating, setValue] = useState('5');
  var [comment, setComment] = useState('');
  const applyCallback = (e) => {
    const { value } = e.target
    setValue(value)
  }
  const applyCallbackComment = (e) => {
    const { value } = e.target
    setComment(value)
  }
  const submit = () => {
    let appconfig = store.getState().appconfg
    new FeedbackService()
      .update(appconfig.base_path, {
        comment, rating
      })
      .then(() => {
        if (rating + '' === '5' && props.feedbackUrl) {
          window.location.replace(props.feedbackUrl)
        } else {
          history.push('/' + appconfig.base_path + '/article/' + props.article)
        }
      });
  }
  return (<div className="">
    <div className="xfieldset">
      <span className="star-cb-group">
        <input checked={rating === '5'} type="radio" onChange={applyCallback} name="rating" id="rating-5" value="5" /><label htmlFor="rating-5"></label>
        <input checked={rating === '4'} type="radio" onChange={applyCallback} name="rating" id="rating-4" value="4" /><label htmlFor="rating-4"></label>
        <input checked={rating === '3'} type="radio" onChange={applyCallback} name="rating" id="rating-3" value="3" /><label htmlFor="rating-3"></label>
        <input checked={rating === '2'} type="radio" onChange={applyCallback} name="rating" id="rating-2" value="2" /><label htmlFor="rating-2"></label>
        <input checked={rating === '1'} type="radio" onChange={applyCallback} name="rating" id="rating-1" value="1" /><label htmlFor="rating-1"></label>
      </span>
      <p className="font-2">{getTranText2('text_16')}</p>
      <CTextarea
        onChange={applyCallbackComment}
        name="content_2" 
        id="textarea-input" 
        rows="7"
        placeholder="" 
      />
      <div className='text-center mt-2'>
        <CButton onClick={submit} style={props.style} type="submit">{getTranText2('text_17')}</CButton>
      </div>
    </div>
  </div>)
}