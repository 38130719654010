import React, { useEffect, useRef } from "react";
import {QRCodeExt} from "src/plugins/qrCode";
export const QRCode = ({value, size, gid, color, image}) => {
  const ref = useRef(null);
  useEffect(() => {
    const qrCode = new QRCodeExt({
      width: size || 300,
      height: size || 300,
      // image: `/b8b69c0d-bae9-40e2-9d69-20ed60912422.png`,
      image: image ? image : (gid ? `${process.env.REACT_APP_API_URL}static/uploads/${gid}.png` : null),
      type: 'svg',
      gid: gid,
      id: gid,
      dotsOptions: {
        color: color || "#4267b2",
        type: "rounded"
      },
      imageOptions: {
        margin: 0,
        imageSize: 0.4,
        crossOrigin: "anonymous"
      },
      qrOptions: {
        hideBackgroundDots: true
      },
      data: value,
      errorCorrectionLevel: 'L'
    });
    qrCode.update({gid})
    qrCode.append(ref.current);
  }, []);
  return (
    <>
      <div className="qr-warp" ref={ref} />
    </>
  )
}
