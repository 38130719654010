export function appconfg(state = {}, action) {
  var newState = Object.assign([], state)
  switch (action.type) {
    case 'setInfo':
      return action.obj;
    case 'setItems':
      newState.items = action.items
      return newState;
    case 'setMenuId':
      newState.menuId = action.id
      return newState;
    default:
      return state
  }
}