export const cartActions = {
  add,
  remove,
  updateQty,
  clear,
  updateItem,
  updateV2Qty,
  removeV2
};
function add(item) {
  return { type: 'addToCart', item };
}
function remove(id) {
  return { type: 'removeFromCart', id };
}
function removeV2(item) {
  return { type: 'removeFromCartV2', item };
}
function updateQty(item) {
  return { type: 'updateQty', item };
}
function updateItem(item) {
  return { type: 'updateItem', item };
}
function updateV2Qty(item) {
  return { type: 'updateV2Qty', item };
}
function clear(item) {
  return { type: 'clear' };
}