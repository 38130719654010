import {
  CBadge
} from '@coreui/react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { store, getTranText, getTranText2, cloneObj, formatUrl } from 'src/_helpers'
import { cartActions } from 'src/_actions'
import Drawer from 'react-modern-drawer'
import { CurrencyText } from 'src/_components'
import clsx from 'clsx'
import 'react-modern-drawer/dist/index.css'
class AddToCart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isOpenV2: false,
      isOpenV3: false,
      currentItem: null
    }
    this.setDrawer = this.setDrawer.bind(this);
  }
  componentWillUnmount () {
    document.body.style.overflow = "auto";
  }
  setDrawer () {
    this.setState({isOpen: false, isOpenV2: false, isOpenV3: false})
  }
  setSubQty(q) {
    let { cart } = this.props
    var exited = cart.find(e => e.id === this.props.item.id)
    if (exited) {
      if (q) exited.qty++
      if (!q) exited.qty--
      if (exited.qty) {
        store.dispatch(cartActions.updateQty(exited))
      } else {
        store.dispatch(cartActions.remove(exited.id))
        this.setDrawer()
      }
    } else {
      store.dispatch(cartActions.add({...this.props.item, id: this.props.item.id, qty: 1, price: this.props.item.price}))
    }
  }
  addToCartAction () {
    let { cart } = this.props
    let { currentItem } = this.state
    const filterKey = currentItem.meta_data.map(e => e.items.filter(f => f.checked).map(m => m.gid))
    var exited = cart.find(e => JSON.stringify(e._key) === JSON.stringify(filterKey))
    if (exited) {
      exited.qty = currentItem.qty
      store.dispatch(cartActions.updateV2Qty(exited))
    } else {
      store.dispatch(cartActions.add({ ...currentItem, _key: filterKey }))
    }
    this.setDrawer()
  }
  addToCartAction2 () {
    let { cart } = this.props
    let { currentItem } = this.state
    const filterKey = currentItem.gid
    var exited = cart.find(e => e.gid === filterKey)
    if (exited) {
      exited.qty = currentItem.qty
      store.dispatch(cartActions.updateV2Qty(exited))
    } else {
      store.dispatch(cartActions.add({ ...currentItem, _key: filterKey }))
    }
    this.setDrawer()
  }
  setQtyForSubItem (chi, q) {
    let { cart, item } = this.props
    var current = cart.find(e => e.id === item.id)
    var maxSubItem = current ? current.max_sub_item : 0
    var currentItemsCount = current ? current.items.reduce((accumulator, object) => { return accumulator + object.qty }, 0) : 0
    if (q && maxSubItem && currentItemsCount >= maxSubItem) {
      return
    }

    current.items.forEach(ite => {
      if (ite.id === chi.id) {
        if (q) ite.qty++
        if (!q) ite.qty--
      }
    })
    store.dispatch(cartActions.updateItem(current))
  }
  setQty(q) {
    let { cart, item, fromCart } = this.props
    console.log(item)
    if (item.items && item.items.length > 0) {
      this.setState({isOpen: true})
    }
    if (item.meta_data && item.meta_data.length > 0) {
      this.setState({currentItem: {...cloneObj(item), qty: item.qty || 1 }})
      this.setState({isOpenV2: true})
      return false;
    }
    if (fromCart && !(item.items && item.items.length > 0)) {
      this.setState({currentItem: {...cloneObj(item)}})
      this.setState({isOpenV3: true})
    }
    var exited = cart.find(e => e.id === this.props.item.id)
    if (exited) {
      if (q) exited.qty++
      if (!q) exited.qty--
      if (exited.qty) {
        store.dispatch(cartActions.updateQty(exited))
      } else {
        store.dispatch(cartActions.remove(exited.id))
        this.setDrawer()
      }
    } else {
      store.dispatch(cartActions.add({...this.props.item, id: this.props.item.id, qty: 1, price: this.props.item.price}))
    }
  }
  removeCartAction () {
    let { currentItem } = this.state
    store.dispatch(cartActions.removeV2(currentItem))
    this.setDrawer()
  }
  setCurrentItemQty (q) {
    let { currentItem } = this.state
    if (currentItem.qty === undefined || currentItem.qty === null) currentItem.qty = 1
    if (q) currentItem.qty++
    if (!q) {
      if (currentItem.qty > 0) {
        currentItem.qty--
      }
    }
    this.setState({currentItem})
  }
  drawerV1() {
    let { cart, item } = this.props
    let { isOpen } = this.state
    var current = cart.find(e => e.id === item.id)
    var maxSubItem = current ? current.max_sub_item : 0
    var currentItemsCount = current ? current.items.reduce((accumulator, object) => { return accumulator + object.qty }, 0) : 0
    return (<Drawer
      lockBackgroundScroll={true}
      open={isOpen}
      onClose={this.setDrawer}
      direction='bottom'
      className='drawer-content'
    >
      <div className={clsx({
        'drawer-wrap': true,
        isMaxSubItem: maxSubItem && currentItemsCount >= maxSubItem
      })}>
        <div className="dish-block">
          <div className="inner-box">
            <div className="dish-image">
              <img src={formatUrl(item.images[0])} alt=""/>
            </div>
            <div className='dish-center'>
              <div className="title clearfix">
                <div className="ttl clearfix">
                  <h5 className='mb-0 item-name'>{getTranText('title', item.translate_data) || item.title}.</h5>
                </div>
                {(item.price && item.price !== '0') ? <div className="price"><CurrencyText value={item.price} /></div> : ''}
              </div>
              <div className="text desc">
                {getTranText('sort_description', item.translate_data) || item.sort_description}
              </div>
            </div>
            <div className='dish-right'>
              <div><CBadge onClick={() => this.setSubQty(true)} className="pointer c-btn-app btn24" color="warning">+</CBadge></div>
              {current && <div className='text-center'>{current.qty}</div>}
              {current && current.qty >= 1 && <div><CBadge onClick={() => this.setSubQty(false)} className="pointer c-btn-app btn25" color="warning">-</CBadge></div>}
            </div>
          </div>
        </div>
        {(current && current.items && current.items.length > 0) ?
        <div className='list-chil-item'>
          {current.max_sub_item ? <div className='dish-block max-sub-item-text text-right'><i>Chọn tối đa {current.max_sub_item}</i></div> : ''}
          {current.items.map((chil, chilIndx) => {
            return <div className="dish-block" key={chilIndx}>
            <div className="inner-box">
              {/* <div className="dish-image">
                <img src={item.images[0] || 'https://mediacity.co.in/delici/assets/images/resource/menu-image-8.png'} alt=""/>
              </div> */}
              <div className='dish-center'>
                <div className="title clearfix">
                  <div className="ttl clearfix">
                    <h5 className='mb-0 item-name'>{getTranText('title', chil.translate_data) || chil.title}</h5>
                  </div>
                  {(chil.price && chil.price !== '0') ? <div className="price"><CurrencyText value={chil.price} /></div> : ''}
                </div>
                <div className="text desc">
                  {getTranText('sort_description', chil.translate_data) || chil.sort_description}
                </div>
              </div>
              <div className='dish-right btn0c'>
                <div><CBadge onClick={() => this.setQtyForSubItem(chil, true)} className="pointer c-btn-app btn21" color="warning">+</CBadge></div>
                {chil.qty ? <div className='text-center'>{chil.qty}</div> : ''}
                {chil && chil.qty >= 1 && <div><CBadge onClick={() => this.setQtyForSubItem(chil, false)} className="pointer c-btn-app btn22" color="warning">-</CBadge></div>}
              </div>
            </div>
          </div>})}
          {/* <CTextarea
            name="note" 
            rows="2"
            placeholder='Nhập ghi chú'
          /> */}
        </div>
        : ''}
      </div>
    </Drawer>)
  }
  onSubItemChange (e, grIndex, itemIndex, type) {
    const { checked } = e.target;
    let { currentItem } = this.state
    if (type === 1) {
      currentItem.meta_data[grIndex].items = currentItem.meta_data[grIndex].items.map(e => {
        e.checked = false
        return e
      })
    }
    currentItem.meta_data[grIndex].items[itemIndex]['checked'] = checked
    this.setState({currentItem})
  }
  drawerV2() {
    let { isOpenV2, currentItem } = this.state
    var total = 0
    if (currentItem) {
      total = currentItem.qty * (currentItem.price || 0)
      currentItem.meta_data.forEach(gr => {
        gr.items.forEach(g => {
          total = g.checked ? total + (g.price || 0) : total
        })
      });
    }
    return (<Drawer
      lockBackgroundScroll={true}
      open={isOpenV2}
      onClose={this.setDrawer}
      direction='bottom'
      className='drawer-content'
    >
      {currentItem &&
      <div className={clsx({
        'drawer-wrap': true
      })}>
        <div className="dish-block">
          <div className="inner-box">
            <div className="dish-image">
              <img src={formatUrl(currentItem.images[0])} alt=""/>
            </div>
            <div className='dish-center'>
              <div className="title clearfix">
                <div className="ttl clearfix">
                  <h5 className='mb-0 item-name d-flex justify-content-between align-items-center'>
                    <b>{getTranText('title', currentItem.translate_data) || currentItem.title}..</b>
                  </h5>
                </div>
              </div>
              <div className="text desc">
                <div>{(currentItem.price && currentItem.price !== '0') ? <div className="price"><CurrencyText value={currentItem.price} /></div> : ''}</div>
                {getTranText('sort_description', currentItem.translate_data) || currentItem.sort_description}
              </div>
            </div>
          </div>
        </div>
        <div className='list-chil-itxem'>
          {(currentItem.meta_data || []).map((gr, grIndex) => {
            return <div className='meta-item' key={grIndex}>
              <div className='meta-gr-name'><b>{getTranText('title', gr.translate_data) || gr.title}</b></div>
              <div className='meta-gr-items'>
                {(gr.items || []).map((subItem, itemIndex) => {
                  return <div className='meta-gr-item d-flex justify-content-between align-items-center' key={itemIndex + '-item'}>
                      <label className={clsx({
                          'c2r-container': gr.max_item_selected === 1,
                          'c-container': gr.max_item_selected === 0,
                        })}>{getTranText('title', subItem.translate_data) || subItem.title}
                        <input type="checkbox" checked={subItem.checked} onChange={(e) => this.onSubItemChange(e, grIndex, itemIndex, gr.max_item_selected)}/>
                        <span className="checkmark"></span>
                      </label>
                      {(subItem.price && subItem.price !== '0') ? <div className="price">+<CurrencyText value={subItem.price} /></div> : ''}
                    </div>
                })}
              </div>
            </div>
          })}
        </div>
        <div className='d-flex justify-content-center align-items-center'>
          <CBadge onClick={() => this.setCurrentItemQty(false)} className="pointer c-btn-app btn27" color="warning">-</CBadge>
          <div className='text-center'>{currentItem.qty}</div>
          <CBadge onClick={() => this.setCurrentItemQty(true)} className="pointer c-btn-app btn26" color="warning">+</CBadge>
        </div>
        <hr />
        {currentItem.qty ?
          <div className='text-center'><button className='btn c-btn-app c-color btn-add-more-1' onClick={() => this.addToCartAction()}>{getTranText2('ADD_TO_CART')} ~ <CurrencyText value={total} /></button></div>
          :
          <div className='text-center'><button className='btn c-btn-app c-color btn-add-more-1' onClick={() => this.removeCartAction()}>{getTranText2('REMOVE_CART')}</button></div>
        }
      </div>}
    </Drawer>)
  }
  drawerV3() {
    let { isOpenV3, currentItem } = this.state
    var total = 0
    if (currentItem) {
      total = currentItem.qty * (currentItem.price || 0)
    }
    return (<Drawer
      lockBackgroundScroll={true}
      open={isOpenV3}
      onClose={this.setDrawer}
      direction='bottom'
      className='drawer-content'
    >
      {currentItem &&
      <div className={clsx({
        'drawer-wrap': true
      })}>
        <div className="dish-block">
          <div className="inner-box">
            <div className="dish-image">
              <img src={formatUrl(currentItem.images[0])} alt=""/>
            </div>
            <div className='dish-center'>
              <div className="title clearfix">
                <div className="ttl clearfix">
                  <h5 className='mb-0 item-name d-flex justify-content-between align-items-center'>
                    <b>{getTranText('title', currentItem.translate_data) || currentItem.title}</b>
                  </h5>
                </div>
              </div>
              <div className="text desc">
                <div>{(currentItem.price && currentItem.price !== '0') ? <div className="price"><CurrencyText value={currentItem.price} /></div> : ''}</div>
                {getTranText('sort_description', currentItem.translate_data) || currentItem.sort_description}
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center'>
          <CBadge onClick={() => this.setCurrentItemQty(false)} className="pointer c-btn-app btn27" color="warning">-</CBadge>
          <div className='text-center'>{currentItem.qty}</div>
          <CBadge onClick={() => this.setCurrentItemQty(true)} className="pointer c-btn-app btn26" color="warning">+</CBadge>
        </div>
        <hr />
        {currentItem.qty ?
          <div className='text-center'><button className='btn c-btn-app c-color btn-add-more-1' onClick={() => this.addToCartAction2()}>{getTranText2('ADD_TO_CART')} ~ <CurrencyText value={total} /></button></div>
          :
          <div className='text-center'><button className='btn c-btn-app c-color btn-add-more-1' onClick={() => this.removeCartAction()}>{getTranText2('REMOVE_CART')}</button></div>
        }
      </div>}
    </Drawer>)
  }
  render() {
    let { cart, item, fromCart } = this.props
    if (fromCart) return <>
      <button onClick={() => this.setQty(true)} className='btn c-btn-app c-color btn-add-edut-1 btn-sm'>{getTranText2('edit_cart_item')}</button>
      {this.drawerV1()}
      {this.drawerV2()}
      {this.drawerV3()}
    </>;
    var current = cart.find(e => e.id === item.id)
    var qty = cart.filter(e => e.id === item.id).reduce((accumulator, object) => { return accumulator + object.qty }, 0)
    var maxSubItem = current ? current.max_sub_item : 0
    var currentItemsCount = current ? current.items.reduce((accumulator, object) => { return accumulator + object.qty }, 0) : 0
    return (
      <>
        <div className={clsx({
          'cart-mini': true,
          isMaxSubItem: maxSubItem && currentItemsCount >= maxSubItem
        })}>
          <div><CBadge onClick={() => this.setQty(true)} className="pointer c-btn-app btn26" color="warning">+</CBadge></div>
          {current && <div className='text-center'>{qty}</div>}
          {current && current.qty >= 1 && <div><CBadge onClick={() => this.setQty(false)} className="pointer c-btn-app btn27" color="warning">-</CBadge></div>}
        </div>
        {this.drawerV1()}
        {this.drawerV2()}
      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    cart: state.cart
  };
};
export default connect(mapStateToProps)(AddToCart)
