import React, { useEffect, useState } from "react";
import {
  QRCode
} from "src/_components";
import NProgress from 'nprogress'
export const RoomQrCode = ({ printData, printBg, qrColor, cssPrint, css2, minSize, maxSize }) => {
  const [printDatas, setPrintDatas] = useState([])
  const [mytime, setMytime] = useState(0);

  useEffect(() => {
    const myInterval = setInterval(() => {
      setMytime((prevTime) => prevTime + 1);
    }, 250);
    return () => clearInterval(myInterval);
  }, []);
  
  useEffect(() => {
    var inter = 0
    NProgress.start()
    const interVal = setInterval(() => {
      if (!printData[inter]) {
        clearInterval(interVal)
        NProgress.done()
      } else {
        printDatas.push(printData[inter])
        setPrintDatas(printDatas)
        NProgress.start()
        inter++;
      }
    }, 500)
    return () => {
      interVal && clearInterval(interVal);
      NProgress.done()
    };
  }, []);
  return (<>
    {printDatas.filter(e => e.status).map((e, ind) => {
      return <div className="print-item text-center" key={ind} id={e.gid}>
        {printBg && <img className="hidden" src={printBg} />}
        <QRCode image={e.img} gid={e.gid} color={qrColor} title={e.title} value={e.link} size={cssPrint === css2 ? minSize : maxSize} />
      </div>
    })}
  </>)
}
