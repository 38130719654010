import {
  CButton,
  CCol,
  CRow
} from '@coreui/react'
import {
  TransText
} from "src/_components";
import { history } from '../_helpers';
export const FormAction = (props) => {
  return (
    <CRow>
      <CCol md="2" className="right">
        <CButton onClick={() => history.goBack()} type="button" color="secondary"><TransText>Quay lại</TransText></CButton>
      </CCol>
      <CCol md="10" className="text-right">
        <div className='d-flex justify-content-end'>
          {props.children}
          <CButton onClick={() => props.onClick(1)} disabled={props.disabled} type="button" className={'mr-2'} color="info"><TransText>Lưu và tiếp tục chỉnh sửa</TransText></CButton>
          <CButton onClick={() => props.onClick(2)} disabled={props.disabled}  type="button" className={'mr-2'} color="warning"><TransText>Lưu và quay lại</TransText></CButton>
          <CButton onClick={() => props.onClick(3)} disabled={props.disabled} type="button" className={'mr-2'} color="success"><TransText>Lưu và tạo mới</TransText></CButton>
        </div>
      </CCol>
    </CRow>
  )
}