import React, { useState, useEffect } from "react";
import MultiSelect from "@kenshooui/react-multi-select";
export const SelectMultiInputV2 = props => {
  const [options, setOptions] = useState(props.options || []);

  const applyCallback = value => {
    console.log(value)
    if (props.onChange)
      props.onChange({
        target: {
          name: props.name,
          value: (value || []).map(e => e.value)
        }
      });
  };
  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const objValue = options.filter(e => props.value.includes(e.value)) || [];
  return (
    <>
      {props.readonly ? (
        <div className="fake-form-control">
          {objValue.map(e => e.label).join(", ")}
        </div>
      ) : (
        <MultiSelect
          items={options}
          selectedItems={objValue}
          onChange={applyCallback}
        />
      )}
    </>
  );
};
