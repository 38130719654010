import BaseService from './base.service'
export class FeedbackService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`public/api/feedback`, parameters)
  }
}