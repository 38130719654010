import React, { Component } from 'react';
import { InputText, CEditor, Uploader } from "src/_components";
import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CTextarea
  } from '@coreui/react'
import { getListLanguage } from 'src/_helpers'
// http://www.lingoes.net/en/translator/langcode.htm
export class Trans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      type: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.applyCallbackFiles = this.applyCallbackFiles.bind(this);
  }
  handleChange(e) {
    const { value } = e.target;
    var type = { ...this.state.type };
    type.value = value;
    this.setState({ type });
  }
  applyCallbackFiles(a, b) {
    console.log(a, b)
    var type = { ...this.state.type };
    type.value = a[0];
    this.setState({ type });
  }
  setModal (type) {
    if (type) {
      var { fieldName, value } = this.props
      var name = `${fieldName}_${type.code}`
      type.value = value[name];
      this.setState({type})
      // this.setState({data: cloneObj(value)})
    } else {
      this.setState({type: null})
    }
  }
  emit () {
    var { fieldName } = this.props
    const { type } = this.state
    var name = `${fieldName}_${type.code}`
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          name: name,
          value: type.value
        }
      });
      this.setState({type: null})
    }
  }
  inputForm () {
    const { type } = this.state
    var { fieldName } = this.props
    var name = `${fieldName}_${type.code}`
    if (this.props.type === 'CEditor') return <CEditor
      name={name}
      value={type.value}
      onChange={this.handleChange}
    />
    if (this.props.type === 'CTextarea') return <CTextarea
      name={name}
      value={type.value}
      onChange={this.handleChange}
      rows="3"
    />
    if (this.props.type === 'Uploader') return <Uploader
      applyCallback={this.applyCallbackFiles}
      name={name}
      value={type.value}
    />
    return <InputText
      type="text"
      name={name}
      value={type.value}
      onChange={this.handleChange}
    />
  }
  modal () {
    const { type } = this.state
    return (<>
        {type && <CModal
        closeOnBackdrop={false}
        show={type !== null}
        onClose={() => this.setModal(null)}
        color="primary"
        size='lg'
      >
        <CModalHeader closeButton>
          <CModalTitle>Thêm/sửa nội dung ({type.label || type.code})</CModalTitle>
        </CModalHeader>
        <CModalBody className='mb-2'>
          <div>
            {this.inputForm()}
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton size="sm" onClick={() => this.emit()} className="float-right" color="info">Đồng ý</CButton>
        </CModalFooter>
      </CModal>}
    </>)
  }
  render() {
    const { type } = this.state
    const ListLang = getListLanguage()
    return (
      <>
        <div className='list-lang'>
            {ListLang.map(l => {
                return <span className='pointer' onClick={e => this.setModal(l)} key={l.code}><img alt='qr-flag0i' src={`/static/images/flag/${l.code}.png`} /></span>
            })}
        </div>
        {type && this.modal()}
      </>
    )
  }
}
